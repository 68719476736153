<template>
  <div>
    <v-container>
      <h1 class="primary--text pl-0 pt-6">
        {{ $t("navbar.navigation.advacedSearch") }}
      </h1>
      <Breadcrumb
        v-if="$vuetify.breakpoint.smAndUp"
        :items="breadcrumb"
        class="custom-breadcrumb align-left"
      />
      <v-form ref="form" v-model="isFormValid">
        <v-row class="pt-6">
          <v-col cols="12" md="6">
            <h3 class="primary--text">
              {{ $t("common.gtin") }}
            </h3>
            <v-textarea
              v-model.trim="searchParamGTIN"
              :label="$t('common.enterValue')"
              :hint="$t('advanceSearch.gtinHint')"
              persistent-hint
              solo
              flat
              outlined
              auto-grow
              rows="6"
              :rules="rules"
              @blur="onBlur"
            ></v-textarea>
            <!-- <h3 class="primary--text">
            {{ $t("advanceSearch.brand") }}
          </h3>
          <v-text-field
            v-model="searchParamBrand"
            :label="$t('common.enterValue')"
            append-icon="mdi-magnify"
            dense
            solo
            flat
            outlined
          ></v-text-field> -->
            <!-- <v-row no-gutters>
            <v-col cols="12" sm="6">
              <h3 class="primary--text">
                {{ $t("advanceSearch.productStatus") }}
              </h3>
              <v-select
                dense
                solo
                flat
                :items="status"
                :label="$t('common.select')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="pl-3">
              <h3 class="primary--text">
                {{ $t("advanceSearch.market") }}
              </h3>
              <v-checkbox label="Italia" hide-details class="mt-0"></v-checkbox
            ></v-col>
          </v-row> -->
            <!-- <v-row no-gutters>
            <v-col cols="12" sm="6">
              <h3 class="primary--text">
                {{ $t("advanceSearch.use") }}
              </h3>
              <v-select
                dense
                solo
                flat
                :items="uses"
                :label="$t('common.select')"
                outlined
              ></v-select>
            </v-col>
          </v-row> -->
            <h3 class="primary--text">
              {{ $t("advanceSearch.productVersionDate") }}
            </h3>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatedDate"
                  :label="$t('common.select')"
                  append-icon="mdi-calendar"
                  @click:append="menu = true"
                  readonly
                  dense
                  hide-details="auto"
                  solo
                  flat
                  outlined
                  clearable
                  @click:clear="clearDates"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :min="date[0]"
                no-title
                scrollable
                range
                color="primary"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  {{ $t("common.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date)"
                  :disabled="disableSelectDate"
                >
                  {{ $t("common.select") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-checkbox
              v-model="webEnabled"
              :label="$t('advanceSearch.webEnabled')"
              hide-details
            ></v-checkbox>
            <!-- <h3 class="primary--text">
            {{ $t("advanceSearch.area") }}
          </h3>
          <v-text-field
            :label="$t('advanceSearch.areaLabel')"
            append-icon="mdi-magnify"
            v-model.trim="area"
            dense
            solo
            flat
            outlined
            @keydown.enter="addArea"
          ></v-text-field>
          <div class="d-flex flex-wrap" v-if="search.area.length > 0">
            <div class="align-self-center">
              {{ $t("advanceSearch.youSelected") }}
            </div>
            <v-chip
              v-for="(name, index) in search.area"
              :key="index"
              close
              close-icon="mdi-close"
              color="secondary"
              label
              class="px-3 ma-1"
              @click:close="removeArea(name)"
            >
              {{ name }}
            </v-chip>
          </div> -->
          </v-col>
        </v-row>
        <div class="d-flex justify-start pt-6">
          <v-btn
            color="primary"
            class="text-capitalize mr-3 search-btn"
            @click="doSearch()"
            :disabled="disableSearch"
          >
            {{ $t("navbar.search") }}
          </v-btn>
          <!-- <v-btn color="primary" outlined class="text-capitalize">
          {{ $t("advanceSearch.saveSearch") }}
        </v-btn> -->
        </div>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
export default {
  name: "CartItemList",
  components: { Breadcrumb },
  data() {
    return {
      searchParamGTIN: "",
      searchParamVersionDate: "",
      searchParamBrand: "",
      searchParamDateFrom: "",
      searchParamDateTo: "",
      search: { area: [] },
      area: null,
      breadcrumb: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Ricerca avanzata",
          disabled: true,
          href: "/"
        }
      ],
      status: [
        "Attivo",
        "In attesa di approvazione",
        "Distribuzione in sospeso",
        "Sospeso rifiutato",
        "Cancellazione elenco in sospeso"
      ],
      uses: [
        "Inserimento dati fornitori",
        "Pianificazione spazio",
        "Web e stampa"
      ],
      date: [],
      webEnabled: false,
      menu: false,
      isFormValid: false,
      rules: [
        value => {
          const pattern = /^\d+(?:[,\r?\n]\d+)*$/;
          return pattern.test(value) || this.$t("advanceSearch.gtinNotValid");
        },
        value => this.maxGtins(value) || this.$t("advanceSearch.maxGtins")
      ]
    };
  },
  computed: {
    formatedDate() {
      return this.formatDate(this.date);
    },
    disableSelectDate() {
      return this.date.length < 2;
    },
    disableSearch() {
      return (
        this.date.length < 2 &&
        (this.searchParamGTIN === "" || !this.isFormValid)
      );
    }
  },
  methods: {
    addArea() {
      if (this.area) {
        this.search.area.push(this.area);
        this.area = null;
      }
    },
    removeArea(item) {
      this.search.area.splice(this.search.area.indexOf(item), 1);
    },
    formatDate(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let formated = null;
      if (date[0]) {
        const [year, month, day] = date[0].split("-");
        formated = `${months[month - 1]} ${day}, ${year}`;
      }
      if (date[1]) {
        const [year1, month1, day1] = date[1].split("-");
        formated =
          "" + formated + " - " + `${months[month1 - 1]} ${day1}, ${year1}`;
      }

      return formated;
    },
    removeNewline() {
      setTimeout(() => {
        this.searchParamGTIN = this.searchParamGTIN.replaceAll(
          /\r?\n|\r/g,
          ","
        );
      }, 100);
    },
    onBlur() {
      // this.searchParamGTIN = this.searchParamGTIN.replace(/,\s*$/, "");
      this.searchParamGTIN = this.searchParamGTIN.replaceAll(" ", "");
      if (this.searchParamGTIN === "") {
        this.$refs.form.resetValidation();
      }
    },
    doSearch() {
      var currentQuery = {};
      if (this.searchParamGTIN && this.searchParamGTIN != "") {
        currentQuery.product_codes = this.searchParamGTIN
          .replace(/\n/g, ",")
          .replace(/(?<=,|^)0+(?=[1-9])/g, "");
      }
      if (this.date.length > 1) {
        currentQuery.version_date_from = this.date[0];
        currentQuery.version_date_to = this.date[1];
      }
      currentQuery.web_enabled = !this.webEnabled;
      // currentQuery.dateFrom=this.date;
      /*
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
        this.saveWord(currentQuery.q);
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }
      */
      if (
        currentQuery.product_codes ||
        currentQuery.version_date_from ||
        currentQuery.version_date_to
      ) {
        this.$store.dispatch("category/resetFilters");
        this.$router.push({
          path: "/search",
          name: "Search",
          query: currentQuery
        });
        /*
        this.$nextTick(() => {
          //const autocomplete = this.$refs.autocomplete;
          //autocomplete.blur();
          this.search = currentQuery.q;
        });
*/
      }
    },
    clearDates() {
      this.date = [];
    },
    maxGtins(gtins) {
      var separators = [",", "\n"];
      gtins.replace(new RegExp(separators.join("|"), "g"));
      let gtinArray = gtins
        .split(/\n/g)
        .join(",")
        .split(",");
      return gtinArray.length > 200 ? false : true;
    }
  },
  metaInfo() {
    return {
      title: this.$t("navbar.navigation.advacedSearch")
    };
  }
};
</script>
<style scoped lang="scss">
.align-left {
  margin-left: -15px;
}
h1 {
  font-weight: 500;
  font-size: calc(1.1285714286rem + 1.8285714286vw);
  @media screen and (min-width: 1200px) {
    font-size: 2.5rem;
  }
}
.search-btn {
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>
